import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { useTeams } from "@microsoft/teamsfx-react";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios, { AxiosError, AxiosResponse } from "axios";
import { TeamsFxContext } from "context/teams-context";
import { UrlDialogInfo, app, authentication, dialog } from "@microsoft/teams-js";
//import config from "lib/config";
//import SignupAction from "./SignupAction";
import { ISignup, ISignupAction, ISignupStatus, SignupActionStatus } from "@interfaces/signup.interfaces";
import { Body1Stronger, Button, Caption1, Text } from "@fluentui/react-components";
import { IAppInstance } from "@interfaces/appInstance.interfaces";
//import AppInstanceSelection from "./AppInstanceSelection";
//import PageLayouts from "@components/Layout/PageLayout";
import PageLayout from "../../layout/page-layout";
import SignupActions from "components/signup/signup-actions";
import SignupAppInstances from "components/signup/signup-app-instances";
import SignupHeader from "components/signup/signup-header";
import Breadcrumb from "components/navigation/breadcrumb/breadcrumb";
import { usePPA } from "context/ppa-context";
import { differenceInMinutes, differenceInSeconds, format, isDate, parseISO } from "date-fns";
import SignupComplete from "components/signup/signup-complete";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";

// TODO: <PaddedContent>...</PaddedContent>


export default function SignupIndex() {
    const { t, localeCode } = useL10n<ILocaleStrings>();
    const { getApiClient, getSignupClient, config } = usePPA();

    const navigate = useNavigate();

    //console.log(`SignupIndex -> localeCode ->`, localeCode);

    // TODO: Establish the level of signup required.
    // Consent the core app
    // Select an instance
    // Consent the instance app

    // Don't do this:
    // Get the signup instance and check the result:
    // 1. 401 = consent the core app.
    // 2. 204 = not signed up
    // 3. 200 and a signup body = signed up to an instance

    // Call /signup/test
    // Body: {
    // hasSignupPermission: boolean
    // hasSignupInstance: boolean
    // hasInstancePermission: boolean
    // instance?: {}
    // }
    // 1. 401 = consent the core app.
    // 2. Body 

    type stageType = "actions" | "instanceSelection" | "complete";
    type appType = "none" | "app" | "instance";

    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    const [{ context }] = useTeams();

    //const [showActions, setShowActions] = useState<boolean>(true);
    //const [showInstances, setShowInstances] = useState<boolean>(false);
    const [stage, setStage] = useState<stageType>('actions');
    const [appInstances, setAppInstances] = useState<Array<IAppInstance>>([]);
    const [signupStatus, setSignupStatus] = useState<ISignupStatus>();
    //const [selectedAppInstance, setSelectedAppInstance] = useState<IAppInstance>();
    //const [pageTitle, setPageTitle] = useState<string>('');
    //const [pageDescription, setPageDescription] = useState<string>('');


    const [consented, setConsented] = useState<string>('');
    const [consentingApp, setConsentingApp] = useState<appType>('none');
    const [checkCount, setCheckCount] = useState<number>(0);

    //setAppInstance
    //const [appInstanceSelected, setAppInstanceSelected] = useState<string>('');
    //const [appInstanceSetCount, setAppInstanceSetCount] = useState<number>(0);
    //const [appInstanceSetCode, setAppInstanceSetCode] = useState<number>(0);

    const [signupActions, setSignupActions] = useState<Array<ISignupAction>>([
        {
            id: 'one',
            title: "ppaSignupActionConsentAuditAppTitle",
            description: "ppaSignupActionConsentAuditAppMessage",
            actionLabel: "consent",
            //isComplete: false,
            status: 'checking',
            href: ``
        },
        {
            id: 'two',
            title: "ppaSignupActionSelectRegionTitle",
            description: "ppaSignupActionSelectRegionMessage",
            actionLabel: "select",
            //isComplete: false,
            status: 'checking',
            onClick: () => { setStage('instanceSelection'); }
        },
        {
            id: 'three',
            title: "ppaSignupActionConsentRegionalAppTitle",
            description: "ppaSignupActionConsentRegionalAppMessage",
            actionLabel: "consent",
            //isComplete: false,
            status: 'checking',
            href: ``
        }
    ]);

    //
    // useEffect(() => {
    //     //
    //     const handler = (event: any): void => {
    //         if (event.detail?.error) {
    //             console.log(`Signup -> consent error ->`, event.detail);
    //             setConsented('');
    //         }
    //         else {
    //             console.log(`Signup -> consented -> ${consentingApp}`);
    //             setConsented(new Date().toISOString());
    //         }
    //     };
    //     //
    //     window.addEventListener('consented', handler);
    //     //
    //     return () => window.removeEventListener('consented', handler);
    // }, []);

    //
    useEffect(() => {
        //console.log(`useEffect -> consented -> `, consentingApp, consented);

        // If diff is less that xx, reload the signup status and check the consentingApp status...

        console.log(`useEffect -> consented -> `, consentingApp, consented);
        var timerId: NodeJS.Timeout;
        if (consented) {

            const consentedDateTime = parseISO(consented);
            const diff = differenceInSeconds(new Date(), consentedDateTime);

            const isActionComplete =
                (consentingApp == 'app' && signupStatus?.hasSignupScope)
                ||
                (consentingApp == 'instance' && signupStatus?.hasInstancePermission)

            const timedOut = diff > 30;

            if (timedOut || isActionComplete) {
                console.log(`useEffect -> consented -> done ->`, timedOut, isActionComplete);
                setConsentingApp('none');
                setConsented('');
                setCheckCount(0);
            }
            else {
                timerId = setTimeout(() => {
                    getSignupStatus();
                }, 2000);
            }
        }
        return () => clearTimeout(timerId);

    }, [consented, checkCount]);

    // //
    // useEffect(() => {
    //     console.log(`useEffect -> app instance -> `, appInstanceSetCode, appInstanceSetCount);
    //     var timerId: NodeJS.Timeout;
    //     if (appInstanceSetCode > 0) {

    //         const selectedDateTime = parseISO(appInstanceSelected);
    //         const diff = differenceInSeconds(new Date(), selectedDateTime);

    //         const timedOut = diff > 30;

    //         if (timedOut) {
    //             console.log(`useEffect -> app instance -> timedOut ->`);
    //             setAppInstanceSelected('');
    //             setAppInstanceSetCode(0);
    //             setAppInstanceSetCount(0);
    //         }
    //         else {
    //             timerId = setTimeout(() => {
    //                 //getSignupStatus();
    //                 console.log(`useEffect -> app instance -> TODO: set it again...`);
    //             }, 2000);
    //         }
    //     }
    //     return () => clearTimeout(timerId);

    // }, [appInstanceSetCode, appInstanceSetCount]);


    // TODO: Do this better!!
    const getActionStatus = (actionId: string): SignupActionStatus => {
        switch (actionId) {
            case 'one':
                if (signupStatus?.hasSignupScope) {
                    return 'complete';
                }
                else if (consentingApp === 'app' && consented) {
                    return 'checking';
                }
                else {
                    return 'pending';
                }
            case 'two':
                if (signupStatus?.hasSignupInstance) {
                    return 'complete';
                }
                else {
                    return 'pending';
                }
            case 'three':
                if (signupStatus?.hasInstancePermission) {
                    return 'complete';
                }
                else if (consentingApp === 'instance' && consented) {
                    return 'checking';
                }
                else {
                    return 'pending';
                }
            default:
                return 'checking';
        }
    }

    useEffect(() => {
        console.log(`SignupIndex -> signupStatus -> useEffect -> `, signupStatus);

        if (signupStatus) {
            const updated: Array<ISignupAction> = signupActions.map(action => {

                const status = getActionStatus(action.id);

                switch (action.id) {
                    case 'one':
                        return {
                            ...action,
                            status: status, //signupTestResponse.hasSignupScope ? 'complete' : 'checking',
                            //isComplete: signupTestResponse.hasSignupScope,
                            onClick: () => { openConsent('app', config.clientId || "") },
                            disabled: status === 'checking'
                            //href: `https://login.microsoftonline.com/${context?.user?.tenant?.id}/adminConsent?client_id=${config.clientId}&redirect_uri=${config.aadAppRedirectUri}`
                        };
                    case 'two':
                        return {
                            ...action,
                            status: status, //signupTestResponse.hasSignupInstance ? 'complete' : 'checking',
                            //isComplete: signupTestResponse.hasSignupInstance,
                            disabled: status === 'checking' || signupStatus?.hasSignupInstance || !signupStatus?.hasSignupScope
                            //disabled: false
                        };
                    case 'three':
                        return {
                            ...action,
                            status: status, //signupTestResponse.hasInstancePermission ? 'complete' : 'checking',
                            //isComplete: signupTestResponse.hasInstancePermission,
                            onClick: () => { openConsent('instance', signupStatus?.appInstance?.clientId || "") },
                            //href: `https://login.microsoftonline.com/${context?.user?.tenant?.id}/adminConsent?client_id=${signupTestResponse.appInstance?.clientId}&redirect_uri=${config.aadAppRedirectUri}`,
                            disabled: status === 'checking' || !signupStatus?.hasSignupInstance
                        };
                    default:
                        return action;
                }
            });

            setSignupActions(updated);

            // TODO: Show another stage (complete) and in there, reload the context signup and then on loaded, redirect...
            if (signupStatus?.isSignupComplete) {
                setStage('complete');
            }
        }
    }, [signupStatus, consentingApp, consented]);

    const getSignupStatus = async (): Promise<ISignupStatus | null> => {
        try {
            // TODO: apiClient like the other screens...
            // const apiBaseUrl = config.apiUrl || "";
            // const apiClient = createApiClient(
            //     apiBaseUrl,
            //     new BearerTokenAuthProvider(async () => (await teamsUserCredential?.getToken(""))!.token)
            // );
            // const response = await apiClient.get<ISignupStatus>(`/api/signups/status`);

            const response = await getSignupClient().get<ISignupStatus>(`/api/signups/status`);
            console.log(`Index -> getSignup ->`, response.data);

            setSignupStatus(response.data);
            //setSelectedAppInstance(response.data.appInstance);
            //setActions(response.data);
            setCheckCount(checkCount + 1);
            return response.data;
        }
        catch (ex: unknown) {
            //throw ex; // TODO: Handle the error, don't throw as this is the top level!!
            return null;
        }
    }

    const openConsent = (type: appType, clientId: string) => {
        setConsentingApp(type);

        const redirectUrl = `${window.location.origin}/consent-redirect.html`;
        //const url = `https://login.microsoftonline.com/${context?.user?.tenant?.id}/adminConsent?client_id=${clientId}&redirect_uri=${redirectUrl}`;

        //https://learn.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/auth-tab-aad?tabs=teamsjs-v2
        authentication.authenticate({
            url: `${window.location.origin}/consent-start.html?tenant_id=${context?.user?.tenant?.id}&client_id=${clientId}&redirect_uri=${redirectUrl}`,
            width: 500,
            height: 650
        })
            .then(
                (result: any) => {
                    console.log(`authenticate -> result ->`, result);
                    setConsented(new Date().toISOString());
                }
            )
            .catch((reason: any) => {
                console.log(`authenticate -> catch ->`, reason);
                setConsented('');
            });
    }


    useEffect(() => {
        if (teamsUserCredential && context?.user?.tenant) {
            console.log(`Index -> useEffect on teamsUserCredential and tenant -> has both ->`, teamsUserCredential);

            getSignupStatus();
        }
    }, [teamsUserCredential, context?.user?.tenant]);

    //
    useEffect(() => {
        if (stage == 'actions') {
            //setPageTitle("Welcome to ProvisionPoint Audit");
            //setPageDescription("There's a few steps to take before you can harness the power of ProvisionPoint Audit in your organisation.");
        }
        if (stage == 'instanceSelection') {
            //setPageTitle("Region selection");
            //setPageDescription("Select the most suitable region to use.");

            console.log(`Index -> useEffect on stage ->`, stage);
            // Load the available instances...

            const getAvailableInstances = async () => {
                //const apiBaseUrl = config.apiUrl || "";
                try {
                    const response = await getSignupClient().get(`/api/app-instances`);

                    console.log(`Index -> getAppInstances ->`, response);

                    //IAppInstance
                    setAppInstances(response.data);

                    //setActions(response.data);
                }
                catch (ex: unknown | AxiosError<string>) {
                    console.log(`Index -> getAppInstances error ->`, ex);
                    if (axios.isAxiosError(ex)) {
                        console.log(`Index -> getAppInstances error -> Axios Error`, ex.code);
                        //setLoadErrorCode(ex.code);
                    }
                    else {
                        // ??
                    }
                }
            }
            getAvailableInstances();
        }
    }, [stage]);


    //const setAppInstance = async (appInstance: IAppInstance): Promise<ISignup> => {
    // Maybe all the retrying can happen in this method.
    // Catch -> set timeout and call it again...
    // Until retry count is reached... 


    // const setAppInstance1 = (appInstance: IAppInstance): void => {
    //     console.log(`SignupIndex -> setAppInstance1 ->`, appInstance);
    //     setAppInstance(appInstance)
    //         .then(
    //             (ok: any) => {
    //                 console.log(`SignupWrapper -> setAppInstance1 -> ok`, ok);
    //             },
    //             (error: any) => {
    //                 console.log(`SignupWrapper -> setAppInstance1 -> error`, error);
    //             }
    //         )
    //         .catch((error: any) => {
    //             console.log(`SignupWrapper -> setAppInstance1 -> catck`, error);
    //         });
    // }
    //
    const setAppInstance = async (appInstance: IAppInstance): Promise<ISignup> => {
        console.log(`SignupWrapper -> setAppInstance ->`, appInstance);

        //const apiBaseUrl = config.apiUrl || "";
        try {
            // const apiClient = createApiClient(
            //     apiBaseUrl,
            //     new BearerTokenAuthProvider(async () => (await teamsUserCredential?.getToken(""))!.token)
            // );

            // //setAppInstanceSelected(new Date().toISOString());

            // const response = await apiClient.post<any, AxiosResponse<ISignup>>("/api/signups", { m365TenantId: context?.user?.tenant?.id, appInstanceId: appInstance.id });
            const response = await getSignupClient().post<any, AxiosResponse<ISignup>>("/api/signups", { m365TenantId: context?.user?.tenant?.id, appInstanceId: appInstance.id });

            console.log(`SignupWrapper -> setAppInstance -> response -> `, response.data);

            //setAppInstanceSetCode(response.status);
            // Maybe add an if not complete...
            setStage('actions');
            getSignupStatus();

            return response.data;
        }
        catch (ex: unknown | AxiosError<string>) {
            console.log(`SignupWrapper -> setAppInstance error ->`, ex);
            if (axios.isAxiosError(ex)) {
                console.log(`SignupWrapper -> setAppInstance error -> Axios Error`, ex.response?.status);
                //setLoadErrorCode(ex.code);

                // TODO: Continue to try for 60 seconds while the response is 401
                //setAppInstanceSetCode(ex.response?.status || 0);
                //setAppInstanceSetCount(appInstanceSetCount + 1);

                // const timerId = setTimeout(() => {
                //     console.log(`useEffect -> app instance -> TODO: set it again...`);
                //     setAppInstance1(appInstance);
                // }, 2000);
            }
            else {
                // ??
            }
            throw ex;
        }
    }

    const cancelSetAppInstance = () => {
        setStage('actions');
        getSignupStatus();
    }

    return (
        <div>
            {stage === 'actions' &&
                <>
                    <SignupHeader
                        title={t("ppaSignupWelomeTitle")}
                        description={t("ppaSignupWelomeMessage")} />
                    <SignupActions
                        actions={signupActions} />
                </>}
            {stage === 'instanceSelection' &&
                <>
                    <SignupHeader
                        title={t("ppaSignupRegionSelectionTitle")}
                        description={t("ppaSignupRegionSelectionMessage")} />
                    <SignupAppInstances
                        appInstances={appInstances}
                        onSelect={setAppInstance}
                        onCancel={cancelSetAppInstance} />
                </>}
            {stage === 'complete' &&
                <SignupComplete />}
        </div>
    );
}