import { PropsWithChildren, useContext } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IActionRequestsGridProps } from "./action-requests-grid.props";
import Grid from "components/grid/grid";
import { ITableColumn } from "@interfaces/common.interfaces";
import { IActionRequest } from "@interfaces/actions/actions-common.interfaces";
import NavigateCell from "components/reports-common/report-results-grid-cells/navigate-cell/navigate-cell";
import IconWithLabel from "components/reports-common/report-results-grid-cells/common/icon-with-label/icon-with-label";
import { getActionRequestStatusIcon, getActionRequestStatusKey } from "lib/action-requests";
import PrincipalsCell from "components/reports-common/report-results-grid-cells/principals-cell/principals-cell";
import DateTimeCell from "components/reports-common/report-results-grid-cells/date-time-cell/date-time-cell";
import PercentageBar from "components/common/percentage-bar/percentage-bar";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { IScreenPlaceholderProps } from "components/common/screen-placeholder/screen-placeholder.props";
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";
import { getActionTypeKey } from "lib/actions";

const layoutStyles = makeStyles({
    mainWrapper: {
        // ...shorthands.borderWidth('1px', '0'),
        // ...shorthands.borderStyle('solid', 'none'),
        // ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        // ...shorthands.padding('0', '2em')
        flexGrow: 1,
        ...shorthands.padding(0, '1em'),
    }
});

export default function ActionRequestsGrid(props: IActionRequestsGridProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    //
    const cols: Array<ITableColumn<IActionRequest>> = [
        // {
        //     key: "actionType", label: "Action type", minWidth: 300,
        //     renderCell: (key: string, item: any) => (<NavigateCell label={item.actionName} url={item.actionRequestUrl} />)
        // },
        {
            key: "actionName", label: t("ppaActionType"), minWidth: 300,
            renderCell: (key: string, item: IActionRequest) => (t(getActionTypeKey(item.actionType)))
        },
        {
            key: "status", label: t("status"), minWidth: 100,
            renderCell: (key: string, item: IActionRequest) => (
                <IconWithLabel
                    icon={getActionRequestStatusIcon(item.status)}
                    label={t(getActionRequestStatusKey(item.status))} />
            )
        },
        {
            key: "percentageComplete", label: t("percentageComplete"), minWidth: 200,
            renderCell: (key: string, item: IActionRequest) => (
                <PercentageBar
                    value={item.percentageComplete}
                    valueDecimalPlaces={1}
                    showLabel={true} />
            )
        },
        {
            key: "requestedBy", label: t("requestedBy"), minWidth: 200,
            renderCell: (key: string, item: IActionRequest) => (<PrincipalsCell columnId={key} item={item} />)
        },
        {
            key: "requested", label: t("requested"), minWidth: 200,
            renderCell: (key: string, item: IActionRequest) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />)
        },
        {
            key: "finished", label: t("completed"), minWidth: 200,
            renderCell: (key: string, item: IActionRequest) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />)
        }
    ];

    const noItemsScreenPlaceholderProps: IScreenPlaceholderProps = {
        imageUrl: "images/EmptyReportImage.png",
        title: t("ppaNoRequestsTitle"),
        message: t("ppaNoActionRequestsMessage")
    }

    return (
        <div className={styles.mainWrapper}>
            {props.items.length === 0 &&
                <ScreenPlaceholder {...noItemsScreenPlaceholderProps} />}
            {props.items.length > 0 &&
                <Grid
                    columns={cols}
                    items={props.items}
                    selectionMode="single"
                    onSelectionChanged={props.onSelectionChanged} />}
        </div>
    );
}