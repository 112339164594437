import { IPrincipalPickerProps } from "./principal-picker.props";
import PrincipalCard from "components/common/principal-card/principal-card";
import ObjectPicker from "../object-picker/object-picker";


export default function PrincipalPicker(props: IPrincipalPickerProps) {

    const getSearchUrl = (search: string): string => {
        let principalTypes = ``;
        let userType = ``;

        if (props.principalTypes) {
            principalTypes = `&principalTypes=${props.principalTypes.join(`,`)}`;
        }
        if (props.userType) {
            userType = `&userType=${props.userType}`;
        }
        return `/api/principals/search?search=${search}${principalTypes}${userType}`;
    }

    return (
        <ObjectPicker
            getSearchUrl={getSearchUrl}
            onChange={props.onChange}
            model={props.model}
            label={props.label || "principalPickerUserOrGroupLabel"}
            objectCard={<PrincipalCard item={undefined} />} />
    );
}