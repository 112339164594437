import { useState } from "react";
import { IGuestUsersInWorkspaceReportDetails, IGuestUsersInWorkspaceReportItem } from "@interfaces/reports/guest-user-reports.interfaces";
import { ITableColumn, IWorkspace } from "@interfaces/common.interfaces";
import ReportSection from "../../../reports-common/report-section/report-section";
import WorkspacePicker from "../../../controls/workspace-picker/workspace-picker";
import { IReportSectionProps } from "../../../reports-common/report-section/report-section.props";
import { IRequestModel } from "@interfaces/reports/reports-common.interfaces";
import DateTimeCell from "components/reports-common/report-results-grid-cells/date-time-cell/date-time-cell";
import CheckedCell from "components/reports-common/report-results-grid-cells/checked-cell/checked-cell";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { getWorkspaceRoleTypeKey } from "lib/workspaces";


// TODO: Somewhere central. e.g. in the navitems????
const reportUrl: string = "/api/reports/guest-users-in-workspace";

export default function GuestUsersInWorkspaceReport() {

  const { t } = useL10n<ILocaleStrings>();
  const [model, setModel] = useState<IRequestModel>({});
  const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace>();

  // TODO: Each column defined somewhere central and put together here.
  const cols: Array<ITableColumn<IGuestUsersInWorkspaceReportItem>> = [
    { key: "displayName", label: t("displayName"), showInGrid: true, showInExport: true, minWidth: 100 },
    { key: "email", label: t("email"), showInGrid: true, showInExport: true, minWidth: 100, idealWidth: 200 },
    { key: "upn", label: t("upn"), showInGrid: false, showInExport: true, minWidth: 100, idealWidth: 300 },
    { key: "objectId", label: t("entraUserId"), showInGrid: false, showInExport: true },
    //{ key: "memberOfType", label: "Type", showInGrid: true, showInExport: true, minWidth: 100 }, // TODO: Maybe this is an icon column?
    {
      key: "memberOfRole", label: t("role"), showInGrid: true, showInExport: true, minWidth: 100,
      renderCell: (key: string, item: IGuestUsersInWorkspaceReportItem) => {
        return item.memberOfRole ? t(getWorkspaceRoleTypeKey(item.memberOfRole)) : ""
      },
      getExportValue: (key: string, item: IGuestUsersInWorkspaceReportItem) => {
        return item.memberOfRole ? t(getWorkspaceRoleTypeKey(item.memberOfRole)) : ""
      }
    },
    //{ key: "customPermission", label: "Custom permission", showInGrid: true, showInExport: true, minWidth: 150 },
    { key: "created", label: t("created"), showInGrid: true, showInExport: true, renderCell: (key: string, item: IGuestUsersInWorkspaceReportItem) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />) },
    { key: "deleted", label: t("deleted"), showInGrid: false, showInExport: true, renderCell: (key: string, item: IGuestUsersInWorkspaceReportItem) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />) },
    {
      key: "pendingAcceptance", label: t("pending"), showInGrid: true, showInExport: true,
      renderCell: (key: string, item: IGuestUsersInWorkspaceReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
      getExportValue: (key: string, item: IGuestUsersInWorkspaceReportItem) => {
        return item.pendingAcceptance ? t("yes") : t("no");
      }
    },
    {
      key: "accountDisabled", label: t("disabled"), showInGrid: true, showInExport: true,
      renderCell: (key: string, item: IGuestUsersInWorkspaceReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
      getExportValue: (key: string, item: IGuestUsersInWorkspaceReportItem) => {
        return item.accountDisabled ? t("yes") : t("no");
      }
    },
    {
      key: "orphaned", label: t("orphaned"), showInGrid: true, showInExport: true,
      renderCell: (key: string, item: IGuestUsersInWorkspaceReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
      getExportValue: (key: string, item: IGuestUsersInWorkspaceReportItem) => {
        return item.orphaned ? t("yes") : t("no");
      }
    },
    { key: "lastLogin", label: t("lastLogin"), showInGrid: false, showInExport: false } // TODO: Only if data available/P1 licence. Maybe a flag in settings? Or check the tenant settings for P1 lic?
  ];

  const onWorkspaceChange = (value?: IWorkspace) => {
    console.log(`GuestUsersInWorkspaceReport -> onWorkspaceChange -> `, value);
    setModel({ ...model, workspace: { siteUrl: value?.siteUrl } });
    setSelectedWorkspace(value);
  }

  const isModelValid = (): boolean => {
    return model.workspace?.siteUrl ? true : false;
  }

  const reportSectionProps: IReportSectionProps<IGuestUsersInWorkspaceReportItem, IGuestUsersInWorkspaceReportDetails> = {
    columns: cols,
    summaryComponent: <></>,
    reportUrl: reportUrl,
    model: model,
    isModelValid: isModelValid(),
    noItemsMessage: t("ppaGuestUsersInWorkspaceReportNoItemsMessage"),
    defaultExportFilenamePrefix: "ppaGuestUsersInWorkspaceReportDefaultExportFilenamePrefix"
  }

  return (
    <ReportSection {...reportSectionProps}>
      <WorkspacePicker onChange={onWorkspaceChange} model={selectedWorkspace} />
    </ReportSection>
  );
}
