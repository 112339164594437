import { PropsWithChildren, useMemo, cloneElement, useRef } from "react";
import { ICommandBarProps } from "./command-bar.props";
import { Button, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, Overflow, OverflowItem, OverflowItemProps, Toolbar, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens, useIsOverflowItemVisible, useOverflowMenu } from "@fluentui/react-components";
import {
    MoreHorizontalRegular
} from "@fluentui/react-icons";
import { CommandBarItemPlacement, ICommandBarItem } from "@interfaces/common.interfaces";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: "flex",
        flexWrap: "nowrap",
        minWidth: 0,
        ...shorthands.overflow('hidden'),
        ...shorthands.borderWidth('0', '0', '1px', '0'),
        ...shorthands.borderStyle('solid', 'none'),
        ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        ...shorthands.padding('0.25em', '2em')
    },
    leftWrapper: {
        flexGrow: 1
    },
    rightWrapper: {

    }
});

export default function CommandBar(props: PropsWithChildren<ICommandBarProps>) {

    const styles = layoutStyles();

    const getItems = (placement: CommandBarItemPlacement): Array<ICommandBarItem> => {
        return props.items ? props.items?.filter(i => i.placement === placement).sort((a, b) => a.order - b.order) : [];
    };

    return (
        <div className={styles.mainWrapper}>
            <Overflow>
                <div className={styles.leftWrapper}>
                    {getItems('left')?.map((item, idx) => (
                        <OverflowableItem key={item.id} {...item} />
                    ))}
                    <OverflowMenu items={getItems('left')} />
                </div>
            </Overflow>
            <div className={styles.rightWrapper}>
                {getItems('right').map((item, idx) => (
                    <FixedItem key={item.id} {...item} />
                ))}
            </div>
        </div>
    );
}

export function FixedItem(item: ICommandBarItem) {
    if (item.hidden)
        return <></>;

    if (!item.component)
        return <></>;

    return cloneElement(item.component);
}

export function OverflowableItem(item: ICommandBarItem) {
    if (item.hidden)
        return <></>;

    if (!item.component)
        return <></>;

    return <OverflowItem id={item.id}>{cloneElement(item.component)}</OverflowItem>
}

export function OverflowMenuItem(item: ICommandBarItem) {
    const isVisible = useIsOverflowItemVisible(item.id);

    if (isVisible) {
        return null;
    }

    if (item.hidden)
        return <></>;

    if (!item.component)
        return <></>;

    return cloneElement(item.component, { isOverflowingMenu: true });
}

export function OverflowMenu(props: { items?: Array<ICommandBarItem> }) {
    const { ref, overflowCount, isOverflowing } =
        useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing) {
        return null;
    }

    return <Menu>
        <MenuTrigger disableButtonEnhancement>
            <Button
                ref={ref}
                icon={<MoreHorizontalRegular />}
                appearance="subtle" />
        </MenuTrigger>

        <MenuPopover>
            <MenuList>
                {props.items?.map((item, idx) => {
                    return <OverflowMenuItem key={idx} {...item} />;
                })}
            </MenuList>
        </MenuPopover>
    </Menu>;
};