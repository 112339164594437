import { PropsWithChildren, cloneElement, useState } from "react";
import { Button, Spinner, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IActionFormButtonsProps } from "./action-form-buttons.props";
import { getError } from "lib/utils";
import { IApiError } from "@interfaces/common.interfaces";
import ApiErrorBar from "components/common/api-error-bar/api-error-bar";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";

const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.margin('1.5em', 0, '0.5em', 0)
    },
    errorWrapper: {
        ...shorthands.margin('1.5em', 0, 0, 0)
    },
    buttonsWrapper: {
        display: 'flex',
    },
    errorLabel: {
        color: tokens.colorStatusDangerForeground1
    },
    left: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    right: {
        display: 'flex',
        justifyContent: 'flex-end',
        '> button': {
            ...shorthands.margin(0, 0, 0, '1em')
        }
    }
});

export default function ActionButtons(props: IActionFormButtonsProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [apiError, setApiError] = useState<IApiError | null>(null);

    const onSubmitHandler = () => {
        console.log(`ActionButtons -> onSubmitHandler ->`);

        setApiError(null);
        setIsSubmitting(true);
        props.onSubmit().then(
            (data: any) => {
                console.log(`ActionButtons-> onSubmitHandler -> data -> `, data);
                setIsSubmitting(false);
            },
            (error: any) => {
                setApiError(getError(error));
                console.log(`ActionButtons -> onSubmitHandler -> error -> `, error);
                setIsSubmitting(false);
            });
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.buttonsWrapper}>
                <div className={styles.left}>
                    {isSubmitting &&
                        <Spinner label={t('sending')} size="tiny" />}
                    {apiError &&
                        <span className={styles.errorLabel}>{t('error')}</span>}
                </div>
                <div className={styles.right}>
                    <Button
                        appearance="primary"
                        onClick={onSubmitHandler}
                        disabled={isSubmitting}>{t('request')}</Button>
                    <Button
                        appearance="secondary"
                        onClick={props.onCancel}
                        disabled={isSubmitting}>{t('cancel')}</Button>
                </div>
            </div>
            {apiError &&
                <div className={styles.errorWrapper}>
                    <ApiErrorBar error={apiError} />
                </div>}
        </div>);
}