import { forwardRef, MouseEventHandler, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { ForwardRefComponent, Menu, MenuButton, MenuItem, MenuItemRadio, MenuList, MenuPopover, MenuProps, MenuTrigger, Toolbar, ToolbarButton, ToolbarDivider, ToolbarGroup, ToolbarProps, ToolbarRadioButton, ToolbarRadioGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    ClipboardTextLtrRegular, TextBulletListRegular, DocumentBulletListRegular
} from "@fluentui/react-icons";
import { IReportResultsToggleProps } from "./report-results-toggle.props";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";

const layoutStyles = makeStyles({

});

export const ReportResultsToggle: ForwardRefComponent<IReportResultsToggleProps> = forwardRef((props, ref) => {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const menuItems = useMemo((): Array<any> => {
        console.log(`useMemo ->`);
        return [
            { value: "items", label: t('ppaReportViewItems'), icon: <TextBulletListRegular /> },
            { value: "summary", label: t('ppaReportViewSummary'), icon: <ClipboardTextLtrRegular /> }
        ]
    }, []);

    const [checkedValues, setCheckedValues] = useState<Record<string, string[]>>({ reportResults: [props.view] });
    const [selectedItem, setSelectedItem] = useState<any>();

    useEffect(() => {
        //console.log(`useEffect -> props.view ->`, props.view);
        const x = menuItems.filter(m => m.value === props.view)[0];
        setCheckedValues((record) => ({ ...record, ['reportResults']: [props.view] }));
        setSelectedItem(x);
    }, [props.view]);

    const Popover = () => {
        return (
            <MenuPopover>
                <MenuList>
                    {menuItems.map((item: any, idx: number) => (
                        <MenuItemRadio
                            key={`reportResults-${item.value}`}
                            name="reportResults"
                            value={item.value}
                            onClick={() => { props.onViewChange(item.value) }}
                            icon={item.icon}>{item.label}</MenuItemRadio>
                    ))}
                </MenuList>
            </MenuPopover>
        );
    }

    if (props.isOverflowingMenu)
        return (
            <Menu checkedValues={checkedValues} >
                <MenuTrigger>
                    <MenuItem
                        icon={selectedItem?.icon}
                        disabled={props.disabled}>{selectedItem?.label}</MenuItem>
                </MenuTrigger>
                <Popover />
            </Menu>
        );

    return (
        <Menu checkedValues={checkedValues}>
            <MenuTrigger>
                <MenuButton
                    ref={ref}
                    disabled={props.disabled}
                    icon={selectedItem?.icon}
                    appearance="subtle">{selectedItem?.label}</MenuButton>
            </MenuTrigger>
            <Popover />
        </Menu>
    );
});