import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "../layout/page-layout";
import { usePPA } from "context/ppa-context";
import { getTopLevelNavigationCardItems } from "lib/utils";
import NavigationCards from "components/navigation/navigation-cards/navigation-cards";


export default function Index() {
  const { hasAccess, isSignupComplete } = usePPA();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasAccess && !isSignupComplete) {
      console.log(`Index -> incomplete signup ->`);
      navigate(`/signup`);
    }
  }, [hasAccess, isSignupComplete]);

  const items = useMemo(() => {
    return getTopLevelNavigationCardItems();
  }, []);

  return (
    <PageLayout>
      <NavigationCards items2={items} />
    </PageLayout >
  );
}