import { ILocaleStrings } from "loc/locale.interfaces";


export const getMailboxPurposeKey = (mailboxPurpose: string): keyof ILocaleStrings => {
    switch (mailboxPurpose) {
        case "User":
            return "ppaMailboxPurposeUser";
        case "Linked":
            return "ppaMailboxPurposeLinked";
        case "Shared":
            return "ppaMailboxPurposeShared";
        case "Room":
            return "ppaMailboxPurposeRoom";
        case "Equipment":
            return "ppaMailboxPurposeEquipment";
        case "Others":
            return "ppaMailboxPurposeOthers";
        default:
            return "unknown";
    }
}