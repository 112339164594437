import { IInitialLocales } from "./locale.interfaces";


//
const preL10nLoadStrings: IInitialLocales = {
    cy: {
        initialising: "Cychwyn"
    },
    de: {
        initialising: "Initialisierung"
    },
    en: {
        initialising: "Initialising"
    },
    es: {
        initialising: "Inicializando"
    },
    fr: {
        initialising: "Initialisation"
    },
    nl: {
        initialising: "Initialiseren"
    },
    te: {
        initialising: "Initialising.test"
    }
}

export default preL10nLoadStrings;