import { useState } from "react";
import { ITableColumn } from "@interfaces/common.interfaces";
import ReportSection from "../../../reports-common/report-section/report-section";
import { IReportSectionProps } from "../../../reports-common/report-section/report-section.props";
import { IRequestModel } from "@interfaces/reports/reports-common.interfaces";
import { IOrphanedUsersInTenantReportDetails, IOrphanedUsersInTenantReportItem } from "@interfaces/reports/orphaned-object-reports.interfaces";
import OrphanedUsersInTenantReportSummary from "components/reports/orphaned-users/orphaned-users-in-tenant-report/orphaned-users-in-tenant-report-summary";
import CheckedCell from "components/reports-common/report-results-grid-cells/checked-cell/checked-cell";
import { RemoveOrphanedUsersActionButton } from "components/actions/orphaned-users/remove-orphaned-users-action/remove-orphaned-users-action-button";
import WorkspaceLinkCell from "components/reports-common/report-results-grid-cells/workspace-link-cell/workspace-link-cell";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { getSharePointTemplateKey } from "lib/workspaces";
//import RemoveOrphanedUsersActionInput from "components/actions/orphaned-users/remove-orphaned-users-action/remove-orphaned-users-action-input";
//import { IRemoveOrphanedUsersSelection, IRemoveOrphanedUsersActionRequest } from "@interfaces/actions/orphaned-user-actions.interfaces";


// TODO: Somewhere central. e.g. in the navitems????
const reportUrl: string = "/api/reports/orphaned-users-in-tenant";

export default function OrphanedUsersInTenantReport() {

  const { t } = useL10n<ILocaleStrings>();
  const [model, setModel] = useState<IRequestModel>({});

  // TODO: Each column defined somewhere central and put together here.
  const cols: Array<ITableColumn<IOrphanedUsersInTenantReportItem>> = [
    {
      key: "workspaceName", label: t("ppaWorkspace"), showInGrid: true, showInExport: true, defaultWidth: 200,
      renderCell: (key: string, item: IOrphanedUsersInTenantReportItem) => (<WorkspaceLinkCell name={item.workspaceName} url={item.workspaceUrl} template={item.workspaceTemplate} type={item.workspaceType} />),
      getExportValue: (key: string, item: IOrphanedUsersInTenantReportItem) => (item.workspaceName ? item.workspaceName : t(getSharePointTemplateKey(item.workspaceTemplate)))
    },
    { key: "workspaceUrl", label: t("ppaWorkspaceUrl"), showInGrid: false, showInExport: true },
    { key: "workspaceType", label: t("ppaWorkspaceType"), showInGrid: false, showInExport: true },
    { key: "workspaceTemplate", label: t("ppaWorkspaceTemplate"), showInGrid: false, showInExport: true },
    { key: "displayName", label: t("displayName"), showInGrid: true, showInExport: true, minWidth: 100 },
    { key: "upn", label: t("upn"), showInGrid: true, showInExport: true },
    { key: "email", label: t("email"), showInGrid: false, showInExport: true },
    { key: "siteUserId", label: t("siteUserId"), showInGrid: false, showInExport: true },
    { key: "aadObjectId", label: t("entraUserId"), showInGrid: true, showInExport: true },
    { key: "spLoginName", label: t("sharePointLoginName"), showInGrid: false, showInExport: true },
    {
      key: "isSiteAdmin", label: t("isSiteAdmin"), showInGrid: true, showInExport: true, minWidth: 100,
      renderCell: (key: string, item: IOrphanedUsersInTenantReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
      getExportValue: (key: string, item: IOrphanedUsersInTenantReportItem) => (item.isSiteAdmin ? t("yes") : t("no"))
    },
    {
      key: "deleted", label: t("deleted"), showInGrid: false, showInExport: true,
      getExportValue: (key: string, item: IOrphanedUsersInTenantReportItem) => (item.deleted ? t("yes") : t("no"))
    }
  ];

  const isModelValid = (): boolean => {
    return true;
  }

  const reportSectionProps: IReportSectionProps<IOrphanedUsersInTenantReportItem, IOrphanedUsersInTenantReportDetails> = {
    columns: cols,
    summaryComponent: <OrphanedUsersInTenantReportSummary temp="the temp prop" />,
    reportUrl: reportUrl,
    model: model,
    isModelValid: isModelValid(),
    noItemsMessage: t("ppaOrphanedUsersInTenantReportNoItemsMessage"),
    defaultExportFilenamePrefix: "ppaOrphanedUsersInTenantReportDefaultExportFilenamePrefix",
    selectionType: 'single',
    buttons: [
      {
        // order, etc...
        component: <RemoveOrphanedUsersActionButton />
      }
    ]
  }

  return (
    <ReportSection {...reportSectionProps}>
    </ReportSection>
  );
}
