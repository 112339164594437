import {
    Body1Stronger,
    Button,
    Caption1,
    Card,
    CardFooter,
    CardHeader,
    makeStyles,
    shorthands,
    tokens
} from "@fluentui/react-components";
import {
    OpenRegular,
    ChevronRightRegular,
    BriefcasePerson24Regular,
    BoxMultipleArrowRight20Regular,
    StarRegular,
    StarAddRegular,
    TextGrammarLightningRegular,
    AppsListDetailRegular
} from "@fluentui/react-icons";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { INavigationCardProps } from "./navigation-card.props";
import NavigationCardChildItem from "./navigation-card-child-item";
import { createElement } from "react";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";


const layoutStyles = makeStyles({
    iconWrapper: {
        fontSize: tokens.fontSizeHero900
    },
    description: {
        fontSize: tokens.fontSizeBase200,
        lineHeight: 'normal'
    }
});

export default function NavigationCard(props: INavigationCardProps) {
    const styles = layoutStyles();
    const navigate = useNavigate();
    const { t } = useL10n<ILocaleStrings>();

    const onSelect = () => {
        navigate(props.item.route);
    }

    const icon = props.item.icon ? createElement(props.item.icon) : null;

    return (
        <Card >
            <CardHeader
                image={<div className={styles.iconWrapper}>{icon}</div>}
                header={<Body1Stronger>{t(props.item.title)}</Body1Stronger>}
                description={props.item.description &&
                    <span
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: t(props.item.description) }}></span>} />
            <div>
                {props.item.childItems?.map((item, idx) => (
                    <NavigationCardChildItem key={item.id} item={item} />
                ))}
            </div>
            <CardFooter>
                <Button
                    onClick={onSelect}
                    icon={<ChevronRightRegular />}
                    iconPosition="after">{t(props.item.actionLabel)}</Button>
                {/* <Button
                    icon={<StarAddRegular />}></Button>
                <Button
                    icon={<AppsListDetailRegular />}
                    onClick={() => navigate('/report-requests?category=storage&report=storageInTenant')}></Button> */}
            </CardFooter>
        </Card>
    );
}