import { Link, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { IPercentageBarProps } from "./percentage-bar.props";


const layoutStyles = makeStyles({
    mainWrapper: {
    },
    barWrapper: {
        backgroundColor: tokens.colorNeutralStroke3,
        display: 'inline-flex',
        width: '150px',
        height: '6px'
    },
    valueBar: {
        //backgroundColor: tokens.colorPaletteLightGreenBorderActive
    },
    spareBar: {
        //backgroundColor: tokens.colorNeutralStroke1,
        //marginLeft: '2px'
    },
    labelWrapper: {
        fontSize: tokens.fontSizeBase200
    },
    additionalLabel: {
        marginLeft: '0.25em'
    }
    // colorPaletteGreenForeground3
    // colorStatusSuccessForeground1
    // colorNeutralStroke1
    // colorNeutralStroke3
    // colorNeutralCardBackground
    // colorNeutralStrokeSubtle
    // colorNeutralBackground5
    // colorPaletteLightGreenForeground1
    // colorPaletteLightGreenBorderActive
});

export default function PercentageBar(props: IPercentageBarProps) {
    const styles = layoutStyles();

    const [valueWidth, setValueWidth] = useState<string>("0%");
    const [spareWidth, setSpareWidth] = useState<string>("0%");
    const [spareMargin, setSpareMargin] = useState<string>("0px");

    const [valueColour, setValueColour] = useState<string>(tokens.colorPaletteLightGreenBorderActive);
    const [spareColour, setSpareColour] = useState<string>(tokens.colorNeutralStroke1);

    useEffect(() => {
        const value = props.value === 0 ? 0 : props.value < 1 ? 1 : props.value;
        const margin = value === 0 ? 0 : 2;
        setValueWidth(`${value}%`);
        setSpareWidth(`${100 - value}%`);
        setSpareMargin(`${margin}px`);
    }, [props.value]);

    useEffect(() => {
        if (props.valueColour)
            setValueColour(props.valueColour);
        if (props.spareColour)
            setSpareColour(props.spareColour);
    }, [props.valueColour, props.spareColour]);

    return (<div className={styles.mainWrapper}>
        <div className={styles.barWrapper}>
            <div
                className={styles.valueBar}
                style={{ width: valueWidth, backgroundColor: valueColour }}></div>
            {props.value < 100 &&
                <div
                    className={styles.spareBar}
                    style={{
                        width: spareWidth,
                        backgroundColor: spareColour,
                        marginLeft: spareMargin
                    }}></div>}
        </div>
        {props.showLabel &&
            <div className={styles.labelWrapper}>
                <span>{props.value?.toFixed(props.valueDecimalPlaces || 0)}%</span>
                {props.additionalLabel &&
                    <span className={styles.additionalLabel}>{props.additionalLabel}</span>}
            </div>}
    </div>);
}