import { makeStyles, shorthands } from "@fluentui/react-components";
import { IScrollPanelProps } from "./scroll-panel.props";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";

const layoutStyles = makeStyles({
    mainWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'grid',
        gridTemplateRows: 'min-content 1fr',
    },
    fixed: {

    },
    scroll: {
        ...shorthands.overflow('auto'),
        position: 'relative'
    }
});

export default function ScrollPanel(props: IScrollPanelProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.fixed}>{props.fixed}</div>
            <div className={styles.scroll}>{props.scroll}</div>
        </div>
    );
}