import { Link, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { ISizeCellProps } from "./size-cell.props";
import { useEffect, useState } from "react";
import { SizeUnits } from "@interfaces/common.interfaces";
import { getSizeLabel } from "lib/utils";


const layoutStyles = makeStyles({
    mainWrapper: {
    }
});

export default function SizeCell(props: ISizeCellProps) {
    const styles = layoutStyles();

    const [value, setValue] = useState<string>("");
    const [units, setUnits] = useState<string>("");

    // // // useMemo...
    // const setIt = (units: SizeUnits): SizeUnits => {
    //     switch (units) {
    //         case "B":
    //             return "KB";
    //         case "KB":
    //             return "MB";
    //         case "MB":
    //             return "GB";
    //         default:
    //             return units;
    //     }
    // }

    useEffect(() => {

        // // TODO: set a number of times it needs to be divided by...

        // let workingValue = props.value;

        // const us = ["B", "KB", "MB", "GB", "TB"];

        // let count = 0;

        // while (workingValue > 1000) {
        //     workingValue = workingValue / 1000;
        //     count++;
        //     //console.log(`workingValue ->`, workingValue);
        // }

        // // TODO: Something if Bytes, convert to KB
        // // Then if less than 0.1 but not 0, show as < 0.1 KB

        //setValue(workingValue.toFixed(2).replace(".00", ""));
        //setUnits(us[count]);

        setValue(getSizeLabel(props.value, props.valueUnits));

    }, [props.value, props.valueUnits]);

    return (<div className={styles.mainWrapper}>
        <span>{value}</span>
    </div>);
}