//import navItemsV2 from "lib/nav-items-v2";
//import NavigationCards from "../../navigation/navigation-cards/navigation-cards";
//import navItems from "lib/nav-items";
import { useContext, useMemo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
//import { INavItemV2, INavigationCardItem } from "@interfaces/common.interfaces";
import NavigationCards from "components/navigation/navigation-cards/navigation-cards";
import { getNavigationCardItems } from "lib/utils";


// TODO: <PaddedContent>...</PaddedContent>


export default function ReportsIndex() {

    const location = useLocation();

    const items = useMemo(() => {
        console.log(`ReportsIndex -> useMemo -> `, location.pathname);
        return getNavigationCardItems(location.pathname);
    }, []);

    return (
        <NavigationCards items2={items} />
    );
}
