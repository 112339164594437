import { PropsWithChildren, useEffect, useState } from "react";
import { Avatar, Persona, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IPrincipal } from "@interfaces/common.interfaces";
import Principal from "../common/principal/principal";
import Principals from "../common/principals/principals";

const layoutStyles = makeStyles({
    mainWrapper: {

    }
});

export default function PrincipalsCell(props: { columnId: string, item: { [key: string]: any } }) {
    const styles = layoutStyles();

    const [principals, setPrincipals] = useState<Array<IPrincipal>>([]);

    useEffect(() => {
        const value = props.item[props.columnId] as IPrincipal | Array<IPrincipal>;
        if (Array.isArray(value))
            setPrincipals(value);
        else
            setPrincipals([value]);
    }, [props.item[props.columnId]]);

    return (
        <div className={styles.mainWrapper}>
            <Principals principals={principals} />
        </div>);
}