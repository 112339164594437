import { useContext } from "react";
import { Link, Outlet } from "react-router-dom";


export default function UsersActionsIndex() {
    return (
        <>
            <div>
                Users Actions Index
            </div>
        </>
    );
}
