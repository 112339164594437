import { ReactElement } from "react";
import { createRoot } from "react-dom/client";
import axios, { AxiosResponse } from "axios";
import "./index.css";
import PPAApp from "components/ppa-app";
import { IAppConfig } from "@interfaces/common.interfaces";

// Get the config.json from the public folder.
// Pass it into the PPA App on initial render as it has the values needed for Teams context and api initialisation.
axios.get('/config.json')
    .then((response: AxiosResponse<IAppConfig>) => {
        return <PPAApp config={response.data} />
    })
    .catch(e => {
        // NOTE: This message is intentionally in English and not translated.
        // At this point the app has not been rendered so we cannot get the language from the Teams context yet.
        // This is the only place where we should use non-translated text in the ui.
        return <div style={{ padding: '2em', color: 'darkred', fontSize: '0.85em' }}>-- error loading configuration --</div>
    })
    .then((reactElement: ReactElement) => {
        // Render the app as either the PPA App (if the config was loaded) or the error message.
        const container = document.getElementById("root");
        const root = createRoot(container!);
        root.render(reactElement);
    });