import { PropsWithChildren, useEffect, useState } from "react";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";


const layoutStyles = makeStyles({
    mainWrapper: {
    }
});

export default function UnknownActionForm(props: any) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    return (
        <div className={styles.mainWrapper}>
            {t('unknown')}
        </div>
    );
}