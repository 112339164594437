import { PropsWithChildren, useEffect, useState } from "react";
import { Card, CardHeader, Text, makeStyles, shorthands, tokens, Caption1, Avatar, CardFooter, Button } from "@fluentui/react-components";
import {
    InfoRegular,
    ArrowReplyRegular,
    ShareRegular,
    ErrorCircleRegular
} from "@fluentui/react-icons";
import { IRemoveOrphanedUsersActionResultCardProps } from "./remove-orphaned-users-action-result-card.props";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";
import { getWorkspaceImageUrl } from "lib/workspaces";

const layoutStyles = makeStyles({
    mainWrapper: {

    },
    divider: {
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStrokeSubtle),
        ...shorthands.margin(0, 0, '1em', 0)
    },
    itemWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...shorthands.margin('1em', 0, 0, 0)
    },
    itemImageWrapper: {
        marginRight: tokens.spacingHorizontalM
    },
    image: {
        width: '32px',
    },
    textWrapper: {
        //whiteSpace: 'nowrap',
        //textOverflow: 'ellipsis',
        ...shorthands.overflow('hidden'),
        //height: '1.2em',
        //display: 'inline-grid',
        //width: '50%'
        //overflow: 'hidden'
        '> span': {
            display: 'block',
            ...shorthands.overflow('hidden'),
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    }
});

export default function RemoveOrphanedUsersActionResultCard(props: IRemoveOrphanedUsersActionResultCardProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [headerTitle, setHeaderTitle] = useState<string>("");
    const [headerDescription, setHeaderDescription] = useState<string>("");
    const [headerImage, setHeaderImage] = useState<any>();
    const [bodyLabel, setBodyLabel] = useState<string>("");
    const [bodyItems, setBodyItems] = useState<Array<any>>([]);

    // TODO: Construct the objects to show...
    // headerTitle
    // headerDescription
    // headerImage

    // <Avatar name={props.item.displayName} image={{ src: imageUrl }} />
    // OR:
    // <img className={styles.image} src={headerImageUrl} />

    useEffect(() => {
        console.log(`RemoveOrphanedUsersActionResultCard ->`);
        if (props.user) {
            setHeaderTitle(props.user.displayName);
            setHeaderDescription(props.user.upn);
            setHeaderImage(<Avatar name={props.user.displayName} image={{ src: "" }} />);

            const bodyLabelKey: keyof ILocaleStrings = props.user.workspaces.length === 1 ?
                "ppaRemoveOrphanedUsersActionRemovedFromSingleWorkspaceMessage" :
                "ppaRemoveOrphanedUsersActionRemovedFromMultipleWorkspacesMessage";

            setBodyLabel(t(bodyLabelKey, { workspaceCount: props.user.workspaces.length }));

            setBodyItems(props.user.workspaces.map((workspace, idx) => {
                return {
                    title: workspace.title,
                    description: workspace.siteUrl,
                    image: <img className={styles.image} src={getWorkspaceImageUrl(workspace.workspaceType)} />
                }
            }));
        }
        else if (props.workspace) {
            setHeaderTitle(props.workspace.title);
            setHeaderDescription(props.workspace.siteUrl);
            setHeaderImage(<img className={styles.image} src={getWorkspaceImageUrl(props.workspace.workspaceType)} />);

            const bodyLabelKey: keyof ILocaleStrings = props.workspace.users.length === 1 ?
                "ppaRemoveOrphanedUsersActionRemovedSingleUserMessage" :
                "ppaRemoveOrphanedUsersActionRemovedMultipleUsersMessage";

            setBodyLabel(t(bodyLabelKey, { userCount: props.workspace.users.length }));

            setBodyItems(props.workspace.users.map((user, idx) => {
                return {
                    title: user.displayName,
                    description: user.upn,
                    image: <Avatar name={user.displayName} image={{ src: "" }} />
                }
            }));
        }
        else {
            // Reset...
            setHeaderTitle("");
            setHeaderDescription("");
            setBodyLabel("");
            setBodyItems([]);
        }
    }, [props.user, props.workspace]);

    //appearance="outline"

    return (
        <Card>
            <CardHeader
                image={headerImage}
                header={<div className={styles.textWrapper}><Text as="span">{headerTitle}</Text></div>}
                description={<div className={styles.textWrapper}><Caption1 as="span">{headerDescription}</Caption1></div>}
            />
            <div>
                <div className={styles.divider}></div>
                <Caption1 as="span">{bodyLabel}</Caption1>
                {bodyItems.map((item, idx) => (
                    <div className={styles.itemWrapper}>
                        <div className={styles.itemImageWrapper}>{item.image}</div>
                        <div>
                            <div className={styles.textWrapper}><Text as="span">{item.title}</Text></div>
                            <div className={styles.textWrapper}><Caption1 as="span">{item.description}</Caption1></div>
                        </div>
                    </div>
                ))}
            </div>
            <CardFooter>
                {props.messages.length > 0 &&
                    <Button onClick={props.onMessagesClick} icon={<InfoRegular fontSize={16} />}></Button>}
                {props.errorCount > 0 &&
                    <Button onClick={props.onErrorsClick} icon={<ErrorCircleRegular fontSize={16} />}>{props.errorCount} Errors</Button>}
            </CardFooter>
        </Card>);
}