import {
    Body1Stronger,
    Button,
    Caption1,
    Card,
    CardHeader,
    Spinner,
    tokens
} from "@fluentui/react-components";
import {
    OpenRegular,
    ChevronRightRegular,
    CheckmarkCircle16Filled,
    CheckmarkCircle20Filled,
    CheckmarkCircleFilled,
    Circle16Regular
} from "@fluentui/react-icons";
import { ISignupAction } from "@interfaces/signup.interfaces";
import { ISignupActionProps } from "./signup-action.props";
import { ReactNode } from "react";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";


export default function SignupAction(props: ISignupActionProps) {
    const { t } = useL10n<ILocaleStrings>();

    const buttonType: string = props.action.onClick ? "button" : "link";

    // colorPaletteLightGreenBackground3
    // colorStatusSuccessBackground3
    // colorNeutralStroke1
    // colorPaletteMarigoldForeground1


    function Status() {
        let labelKey: keyof ILocaleStrings = "unknown";
        let icon: ReactNode = <></>;

        switch (props.action.status) {
            case 'checking':
                labelKey = "ppaSignupStatusChecking";
                icon = <Spinner size="extra-tiny" />;
                break;
            case 'pending':
                labelKey = "ppaSignupStatusPending";
                icon = <Circle16Regular style={{ color: tokens.colorPaletteMarigoldForeground1 }} />;
                break;
            case 'complete':
                labelKey = "ppaSignupStatusComplete";
                icon = <CheckmarkCircle16Filled style={{ color: tokens.colorPaletteLightGreenBackground3 }} />;
                break;
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {icon}
                <Caption1 style={{ marginLeft: '0.25em' }}>
                    {t(labelKey)}
                </Caption1>
            </div>
        );
    }

    return (
        <Card
            style={{ maxWidth: '600px', marginBottom: '1.5em' }}>
            <CardHeader
                header={<Body1Stronger>{t(props.action.title)}</Body1Stronger>}
                description={<Caption1>{t(props.action.description)}</Caption1>}
                action={<Button
                    as={buttonType === 'link' ? 'a' : 'button'}
                    target={buttonType === 'link' ? '_blank' : ''}
                    href={props.action.href}
                    onClick={props.action.onClick}
                    disabled={props.action.disabled}
                    icon={buttonType === 'link' ? <OpenRegular /> : <ChevronRightRegular />}
                    iconPosition="after">{t(props.action.actionLabel)}</Button>} />
            <Status />
        </Card>
    );
}