import { PropsWithChildren } from "react";
import { Button, MessageBar, MessageBarActions, MessageBarBody, MessageBarGroup, MessageBarTitle, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    InfoRegular,
    DismissRegular
} from "@fluentui/react-icons";
import { IApiErrorBarProps } from "./api-error-bar.props";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        backgroundColor: tokens.colorStatusDangerBackground1,
        ...shorthands.borderWidth('1px'),
        ...shorthands.borderStyle('solid'),
        ...shorthands.borderRadius('4px'),
        ...shorthands.borderColor(tokens.colorStatusDangerBorder1),
        ...shorthands.padding('1em')
    },
    iconWrapper: {
        fontSize: '20px',
        color: tokens.colorStatusDangerForeground1
    },
    textWrapper: {
        color: tokens.colorStatusDangerForeground2,
        ...shorthands.padding(0, 0, 0, '0.5em')
    }
});


export default function ApiErrorBar(props: IApiErrorBarProps) {
    const styles = layoutStyles();

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.iconWrapper}><InfoRegular /></div>
            <div className={styles.textWrapper} dangerouslySetInnerHTML={{ __html: props.error.message }}></div>
        </div>
    );
}

