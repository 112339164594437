import { PropsWithChildren, useEffect, useState } from "react";
import { Avatar, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import Principals from "../common/principals/principals";
import { IPrincipal } from "@interfaces/common.interfaces";
import { ISharedLinkInvitation } from "@interfaces/shared-link.interfaces";

const layoutStyles = makeStyles({
    mainWrapper: {

    }
});

export default function InvitationsCell(props: { columnId: string, item: { [key: string]: any } }) {
    const styles = layoutStyles();

    const [invitees, setInvitees] = useState<Array<IPrincipal>>([]);

    useEffect(() => {
        const value = props.item[props.columnId] as Array<ISharedLinkInvitation>;
        //
        setInvitees(value.map((invitation, idx) => { return invitation.invitee }));
    }, [props.item[props.columnId]]);

    return (
        <div className={styles.mainWrapper}>
            <Principals principals={invitees} />
        </div>);
}