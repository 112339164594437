import { PropsWithChildren, useEffect, useState } from "react";
import { Badge, Button, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IReportActionRequestsButtonProps } from "./report-action-requests-button.props";
import {
    TextGrammarLightningRegular
} from "@fluentui/react-icons";
import { useLocation, useNavigate } from "react-router-dom";

const layoutStyles = makeStyles({

});

export default function ReportActionRequestsButton(props: IReportActionRequestsButtonProps) {
    const styles = layoutStyles();

    useEffect(() => {

    }, []);

    //Action requests

    return (
        <Button
            icon={<TextGrammarLightningRegular />}
            // icon={
            //     <div>
            //         <TextGrammarLightningRegular />
            //         <Badge
            //             size="small"
            //             appearance="tint"
            //             color="informative">6</Badge>
            //     </div>}
            disabled={false}
            appearance="subtle"
            onClick={props.onClick} >
            {/* <Badge
                size="small"
                appearance="tint"
                color="informative">6</Badge> */}
        </Button>
    );
}