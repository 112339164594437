import { cloneElement, PropsWithChildren, useEffect, useState } from "react";
import { Input, InputProps, Label, Toolbar, ToolbarButton, makeStyles, shorthands, tokens, useId } from "@fluentui/react-components";
import {
    InfoRegular
} from "@fluentui/react-icons";
import { IWorkspace } from "@interfaces/common.interfaces";
import WorkspaceCard from "components/common/workspace-card/workspace-card";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { IObjectPickerProps } from "./object-picker.props";
import ObjectPickerInput from "./object-picker-input";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap("2px"),
        marginBottom: '1em'
    }
});


export default function ObjectPicker<T>(props: IObjectPickerProps<T>) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const inputId = useId();
    const [selectedObject, setSelectedObject] = useState<T | undefined>(props.model);

    const onInputChange = (value?: string) => {
        console.log(`ObjectPicker -> onInputChange -> `, value);
    }

    const onResultsChange = (results: Array<T>) => {
        console.log(`ObjectPicker -> onResultsChange -> `, results);
    }

    const onSelectObject = (object: T) => {
        console.log(`ObjectPicker -> onSelectObject -> `, object);
        setSelectedObject(object);
    }

    const onClearSelection = (object: T) => {
        console.log(`ObjectPicker -> onClearSelection -> `, object);
        setSelectedObject(undefined);
    }

    useEffect(() => {
        props.onChange(selectedObject);
    }, [selectedObject]);


    return (
        <div className={styles.mainWrapper}>
            <Label htmlFor={inputId}>{t(props.label)}</Label>
            {selectedObject &&
                cloneElement(props.objectCard, { item: selectedObject, onDismissClick: onClearSelection })}
            {/* // <WorkspaceCard item={selectedObject} onDismissClick={onClearSelection} />} */}
            <ObjectPickerInput
                inputId={inputId}
                getSearchUrl={props.getSearchUrl}
                onChange={onInputChange}
                onResultsChange={onResultsChange}
                onSelect={onSelectObject}
                objectCard={props.objectCard}
                visible={selectedObject ? false : true} />
        </div>
    );
}