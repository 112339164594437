import { PropsWithChildren, cloneElement, useContext, useEffect, useState } from "react";
import { Label, Text, Persona, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IReportSummaryProps } from "./report-results-summary.props";
import { format, parseISO } from "date-fns";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { getReportTypeKey } from "lib/report-requests";
//import { L10nContext } from "context/l10n-context";

const layoutStyles = makeStyles({
    columnsWrapper: {
        // ...shorthands.borderWidth('1px', '0'),
        // ...shorthands.borderStyle('solid', 'none'),
        // ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        // ...shorthands.padding('0', '2em')
        display: 'flex',
        flexDirection: 'column',
        '@media (min-width: 768px)': {
            flexDirection: 'row'
        },
    },
    column: {
        //flexGrow: 1,
        //backgroundColor: 'red',
        paddingRight: '1em',
        '@media (min-width: 768px)': {
            maxWidth: '50%'
        },
        '&:first-child': {
            marginRight: '2em'
        }
    },
    heading: {
        marginBottom: '1em'
    },
    requestedBy: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1em'
    }
});

export default function ReportResultsSummary<TI, TD>(props: IReportSummaryProps<TI, TD>) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [requested, setRequested] = useState<string>();
    const [generated, setGenerated] = useState<string>();

    useEffect(() => {
        if (props.report) {
            const requestedDateTime = parseISO(props.report.requested);
            const formattedRequestedDate = isNaN(requestedDateTime.getDate()) ? props.report.requested : format(requestedDateTime, 'PPPp');
            setRequested(formattedRequestedDate);

            if (props.report.generated) {
                const generatedDateTime = parseISO(props.report.generated);
                const formattedGeneratedDate = isNaN(generatedDateTime.getDate()) ? props.report.generated : format(generatedDateTime, 'PPPp');
                setGenerated(formattedGeneratedDate);
            }
        }
    }, [props.report]);



    return (
        <div>
            {props.report &&
                <>
                    <div className={styles.heading}>
                        <Text as="h2" size={400} weight="regular">{t(getReportTypeKey(props.report.reportType))}</Text>
                    </div>
                    <div className={styles.columnsWrapper}>
                        <div className={styles.column}>
                            <div>
                                <div className={styles.requestedBy}>
                                    <Label>{t('requestedBy')}</Label>
                                    <Persona
                                        name={props.report.requestedBy?.displayName}
                                        secondaryText={requested} />
                                </div>
                                {generated &&
                                    <Label>{t('generated', { generated: generated })}</Label>}
                            </div>
                        </div>
                        <div className={styles.column}>
                            {props.summaryComponent && cloneElement(props.summaryComponent, { report: props.report })}
                        </div>
                    </div>
                </>}
        </div>);
}