import {
    FluentIcon,
    QuestionCircleRegular,
    SendRegular,
    CheckmarkCircleRegular,
    ArrowClockwiseDashesRegular,
    SaveRegular,
    WarningRegular
} from "@fluentui/react-icons";
import { ILocaleStrings } from "loc/locale.interfaces";
import navItems from "./nav-items";


// public enum ReportRequestStatus
// {
//     Pending = 0,
//     Processing = 1,
//     Saving = 2,
//     Complete = 3,
//     Failed = 4
// }

export const getReportRequestStatusKey = (status: number): keyof ILocaleStrings => {
    switch (status) {
        case 0:
            return "ppaReportRequestStatus0";
        case 1:
            return "ppaReportRequestStatus1";
        case 2:
            return "ppaReportRequestStatus2";
        case 3:
            return "ppaReportRequestStatus3";
        case 4:
            return "ppaReportRequestStatus4";
        default:
            return "unknown"
    }
}

export const getReportRequestStatusIcon = (status: number): FluentIcon => {
    switch (status) {
        case 0:
            return SendRegular;
        case 1:
            return ArrowClockwiseDashesRegular;
        case 2:
            return SaveRegular;
        case 3:
            return CheckmarkCircleRegular;
        case 4:
            return WarningRegular;
        default:
            return QuestionCircleRegular;
    }
};

export const getReportPageUrl = (reportType: string, requestId: string): string => {
    const item = navItems.filter(n => n.key === reportType);
    return item ? `${item[0].route}/${requestId}` : "/";
};

export const getReportTypeKey = (reportType: string): keyof ILocaleStrings => {
    const item = navItems.filter(n => n.key === reportType);
    return item ? item[0].label : "unknown";
};