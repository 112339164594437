import { PropsWithChildren } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IOrphanedMailboxesInTenantReportSummaryProps } from "./orphaned-mailboxes-in-tenant-report-summary.props";

const layoutStyles = makeStyles({
    mainWrapper: {
    }
});

export default function OrphanedMailboxesInTenantReportSummary(props: IOrphanedMailboxesInTenantReportSummaryProps) {
    const styles = layoutStyles();

    return (<div>
        {/* <div>OrphanedMailboxesInTenantReportDetails</div>
        <div>{props.temp}</div> */}
    </div>);
}