import { PropsWithChildren, useEffect, useState } from "react";
import { Avatar, Persona, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IPrincipal } from "@interfaces/common.interfaces";
import { IPrincipalProps } from "./principal.props";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: tokens.colorNeutralBackground3,
        ...shorthands.borderRadius('12px'),
    },
    label: {
        fontSize: '12px',
        //marginLeft: '5px',
        ...shorthands.margin('0px', '10px', '0px', '5px'),
        //width: '100%',
        overflowX: 'hidden',
        textOverflow: 'ellipsis'
    }
});

export default function Principal(props: IPrincipalProps) {
    const styles = layoutStyles();

    return (
        <div className={styles.mainWrapper}>
            <Avatar
                name={props.principal?.displayName}
                size={24}
            //image={{ src: "https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/KatriAthokas.jpg" }} 
            />
            <span className={styles.label}>{props.principal?.displayName}</span>
        </div>);
}