import { PropsWithChildren } from "react";
import { MessageBar, MessageBarGroup, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    InfoRegular
} from "@fluentui/react-icons";
import { IActionFormRequestMessageProps } from "./action-form-request-message.props";
import MessagesBar from "components/common/messages-bar/messages-bar";

const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.margin('1.5em', 0, 0, 0)
    }
});

export default function ActionFormRequestMessage(props: IActionFormRequestMessageProps) {
    const styles = layoutStyles();

    return (
        <div className={styles.mainWrapper}>
            <MessagesBar
                icon={props.icon}
                message={props.message} />
        </div>
    );
}