import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Card, CardHeader, Text, makeStyles, shorthands, tokens, Caption1, Button } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { IWorkspaceCardProps } from "./workspace-card.props";
//import { IWorkspacePickerItemProps } from "./workspace-picker-item.props";


const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.borderWidth('1px'),
        ...shorthands.borderStyle('solid'),
        ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        ...shorthands.padding('5px'),
        display: 'flex'
    },
    image: {
        width: '28px'
    },
    textWrapper: {
        //whiteSpace: 'nowrap',
        //textOverflow: 'ellipsis',
        ...shorthands.overflow('hidden'),
        //height: '1.2em',
        display: 'inline-grid',
        //width: '50%'
        //overflow: 'hidden'
        '> span': {
            display: 'block',
            ...shorthands.overflow('hidden'),
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    }
});

export default function WorkspaceCard(props: IWorkspaceCardProps) {
    const styles = layoutStyles();

    const [iconUrl, setIconUrl] = useState<string>("images/SharePointLogo.svg");

    // IWorkspace.workspaceType : "SiteCollection" | "SubSite" | "M365Group" | "Planner" | "Yammer" | "Team" | "TeamsPrivateChannel" | "TeamsSharedChannel"

    useEffect(() => {
        switch (props.item.workspaceType) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                setIconUrl("images/SharePointLogo.svg");
                break;
            case 5:
                setIconUrl("images/TeamsLogo.svg");
                break;
            case 6:
                setIconUrl("images/TeamsPrivateChannelLogo.png");
                break;
            case 7:
                setIconUrl("images/TeamsSharedChannelLogo.png");
                break;
            default:
                setIconUrl("images/SharePointLogo.svg");
                break;
        }
    }, [props.item]);

    const onClick = () => {
        console.log(`WorkspaceCard -> onClick -> `, props.item);
        if (props.onSelect)
            props.onSelect(props.item);
    }

    const onDismissClick = () => {
        console.log(`WorkspaceCard -> onDismissClick -> `, props.item);
        if (props.onDismissClick)
            props.onDismissClick(props.item);
    }

    return (
        <Card onClick={props.onSelect ? onClick : undefined} appearance={props.style}>
            <CardHeader
                image={<img className={styles.image} src={iconUrl} />}
                header={<div className={styles.textWrapper}><Text as="span">{props.item.title}</Text></div>}
                description={<div className={styles.textWrapper}><Caption1 as="span">{props.item.siteUrl}</Caption1></div>}
                action={props.onDismissClick && <Button onClick={onDismissClick} appearance="subtle" icon={<DismissRegular />} />}
            />
        </Card>
    );
}