//import navItems from "lib/nav-items";
//import navItemsV2 from "lib/nav-items-v2";
//import NavigationCards from "../../../navigation/navigation-cards/navigation-cards";
import { useContext, useMemo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { getNavigationCardItems } from "lib/utils";
import NavigationCards from "components/navigation/navigation-cards/navigation-cards";


export default function GuestUsersReportsIndex() {

  const location = useLocation();

  const items = useMemo(() => {
    console.log(`ReportsIndex -> useMemo -> `);
    return getNavigationCardItems(location.pathname);
  }, []);

  return (
    <NavigationCards items2={items} />
  );
}
