import { IWorkspacePickerProps } from "./workspace-picker.props";
import WorkspaceCard from "components/common/workspace-card/workspace-card";
import ObjectPicker from "../object-picker/object-picker";


export default function WorkspacePicker(props: IWorkspacePickerProps) {
    return (
        <ObjectPicker
            getSearchUrl={(search: string) => `/api/workspaces/search?search=${encodeURIComponent(search)}`}
            onChange={props.onChange}
            model={props.model}
            label={props.label || "ppaWorkspace"}
            objectCard={<WorkspaceCard item={undefined} />} />
    );
}